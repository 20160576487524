<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md11 lg10 xl8>
        <v-data-table
          dense
          :headers="headers"
          :items="compEmpresasLista"
          class="elevation-10"
          :search="search"
          :loading="!compEmpresasProgress"
          loading-text="Carregando..."
          :footer-props="{
            'items-per-page-options': [10, 20, 30],
          }"
          sortBy="id"
          sort-desc
        >
          <template v-slot:progress>
            <v-progress-linear
              color="primary"
              :height="4"
              indeterminate
            ></v-progress-linear>
          </template>

          <template v-slot:top>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-domain</v-icon>
              <v-toolbar-title>Empresas</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metRecarregar()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metSelecionarEmpresaEdicao()"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </template>
                <span>Novo Empresa</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
              <v-dialog v-model="varDialogExcluir" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">
                    Excluir permanentemente o registro?
                  </v-card-title>
                  <v-container>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        dense
                        class="mb-4 white--text"
                        color="red darken-1"
                        @click="metExcluirEmpresa(varExcluirItem)"
                      >
                        Excluir
                      </v-btn>
                      <v-btn
                        dense
                        class="ml-2 mb-4 white--text"
                        color="blue darken-1"
                        @click="metFecharDialogExcluir()"
                      >
                        Cancelar
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-container>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-container>
              <v-row dense>
                <v-col cols="auto" md="4" class="ml-1 mb-0" align-self="end">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Busca"
                    single-line
                    hide-details
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="ml-2" align-self="end">
                  <v-chip-group mandatory active-class="primary" show-arrows>
                    <v-chip @click="listaEmpEmpresas = 'empresas'">
                      Empresas
                    </v-chip>
                    <v-chip @click="listaEmpEmpresas = 'lixeira'">
                      Lixeira
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  v-if="metEdicaoLixeira()"
                  v-bind="attrs"
                  v-on="on"
                  @click="metSelecionarEmpresaEdicao(item)"
                  color="blue darken-1"
                >
                  mdi-lead-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  color="red darken-4"
                  v-bind="attrs"
                  v-on="on"
                  @click="metExcluirEmpresaDialog(item)"
                  v-if="item.lixeira"
                >
                  mdi-delete-forever
                </v-icon>
              </template>
              <span>Excluir</span>
            </v-tooltip>

            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  :color="metLixeiraColor(item.lixeira)"
                  v-bind="attrs"
                  v-on="on"
                  @click="metParaLixeira(item)"
                >
                  {{ item.lixeira ? "mdi-recycle" : "mdi-trash-can" }}
                </v-icon>
              </template>
              <span>{{ item.lixeira ? "Reciclar" : "Lixeira" }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import register from "./../_store/register";
import DatasMixin from "@/mixins/DatasMixin";
// import moment from "moment";

const { mapState, mapActions, mapGetters } =
  createNamespacedHelpers("cadEmpresas");

export default {
  // components: { ConfirmDialogue },
  mixins: [DatasMixin],
  name: "CadEmpresasLista",
  data: () => ({
    listaEmpEmpresas: "empresas",
    search: "",
    varDialogExcluir: false,
    varExcluirItem: {},
    headers: [
      { text: "ID", value: "id", align: "start" },
      {
        text: "Empresa",
        value: "nomeFantasia",
        sortable: true,
        align: "start",
      },
      {
        text: "CPF/CNPJ",
        value: "cpfCnpj",
        sortable: true,
        align: "start",
      },
      { text: "Ações", value: "actions", sortable: false },
    ],
    // perm: [
    //   {
    //     field: "Pensil",
    //     subject: "action",
    //     component: "LojaClientesComprasLista",
    //     group: [5],
    //   },
    // ],
  }),

  computed: {
    ...mapState(["staErro", "staEmpresas", "staEmpresaSelecionado"]),
    ...mapGetters(["getEmpEmpresas", "getEmpLixeira"]),

    compEmpresasLista() {
      return this.listaEmpEmpresas == "empresas"
        ? this.getEmpEmpresas
        : this.getEmpLixeira;
      // return this.$store.state.cadEmpresas.staEmpresas;
    },
    compEmpresasProgress() {
      // console.log(!this.$store.state.cadEmpresas.staEmpresas.length);
      return this.$store.state.cadEmpresas.staEmpresas;
    },
  },

  created() {
    register(this.$store);
    this.actListarEmpresas(this.$store.state.login.licenca);
  },

  watch: {
    erro() {
      if (this.staErro) {
        this.$root.snackbar.show({
          type: "danger",
          message: this.staErro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
  },

  mounted() {
    //this.obterEmpresas();
  },

  methods: {
    ...mapActions([
      "actListarEmpresas",
      "actSelecionarEmpresa",
      "actResetarEmpresa",
      "actLixeiraEmpresa",
      "actExcluirEmpresa",
    ]),

    metPermissao(field) {
      if (this.perm.some((t) => t.field == field)) {
        const permObject = this.perm.filter((t) => t.field == field);
        if (permObject[0].group[0] == this.$store.state.login.usuario.grupo) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    /* formataData(data) {
      return moment(data).utc().format("DD-MM-YYYY");
    },
    formataDataIn(data) {
      return moment(data).utc().format("YYYY-MM-DD");
    }, */

    async metSelecionarEmpresaEdicao(empresa) {
      if (empresa != undefined) {
        await this.actSelecionarEmpresa({
          id: empresa.id,
          licenca: this.$store.state.login.licenca,
        });
      } else {
        this.actResetarEmpresa();
      }
      await this.$router.push({ name: "CadEmpresasForm" });
    },

    metLixeiraColor(lixeira) {
      if (lixeira) return "orange darken-2";
      else return "red darken-1";
    },

    metParaLixeira(empresa) {
      this.actLixeiraEmpresa({
        empresa: empresa,
        licenca: this.$store.state.login.licenca,
        modificacao: this.$store.state.login.usuario.usuario,
      });
    },

    metEdicaoLixeira() {
      if (this.listaEmpEmpresas == "lixeira") {
        return false;
      }
      return true;
    },

    metExcluirEmpresaDialog(item) {
      this.varExcluirItem = Object.assign({}, item);
      this.varDialogExcluir = true;
    },

    metExcluirEmpresa(empresa) {
      this.actExcluirEmpresa({
        empresa: empresa,
        licenca: this.$store.state.login.licenca,
        modificacao: this.$store.state.login.usuario.usuario,
      });
      this.varDialogExcluir = false;
    },

    metFecharDialogExcluir() {
      this.varDialogExcluir = false;
    },

    metVoltar() {
      this.$router.back();
    },

    metRecarregar() {
      this.actListarEmpresas(this.$store.state.login.licenca);
      this.$root.snackbar.show({
        type: "info",
        message: "Lista atualizada!",
      });
    },
  },
};
</script>

<style></style>
